import { get } from "svelte/store";
import { keywords_store, recommended_titles, settings_store, selected_streamers } from "./store.js";
import { locale } from "svelte-i18n";
import { PUBLIC_API_BASE_URL } from "$env/static/public";
import { goto } from "$app/navigation";
import { locale_options } from "./LocaleConstants.js";

const url = PUBLIC_API_BASE_URL + "/api/search/searchTitles";

export const movieCount = 50;

export async function getTitles() {
  let all_titles = [];
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(getTitlesParams())
    });
    if (!response.ok) {
      throw new Error("Failed to fetch titles");
    }
    const response_json = await response.json();
    all_titles = response_json.data.titles;
  } catch (err) {
    console.error(err.message);
    goto("/error");
  } finally {
    recommended_titles.update(() => {
      return all_titles;
    });
  }
}

function getTitlesParams() {
  const settings = get(settings_store);
  return {
    tags: get(keywords_store),
    locale: locale_options[get(locale)],
    limit: movieCount,
    filters: {
      region: settings.region,
      audio: settings.audio_languages.map((lang) => lang.value),
      subtitles: settings.subtitle_languages.map((lang) => lang.value),
      streamers: get(selected_streamers).map((s) => s.id),
      minProdYear: settings.production_period[0],
      maxProdYear: settings.production_period[1]
    }
  };
}
